<template>
  <div class="mobile">
    <div class="title">永久地址</div>
    <div class="domain" @click="doCopy">{{ addressUrl }}</div>
    <div class="tip">温馨提示:ms越小,反应时间越小,打开就越快</div>
    <div class="subTitleBox">
      <div class="rectangle"></div>
      <div class="subTitle">线路检测</div>
    </div>
    <div v-if="showImg">
      <img
        v-for="(item, index) in urlList"
        :key="index"
        :src="item.url"
        width="1"
        height="1"
        @error="autoTest(item, index)"
        style="display: none"
      />
    </div>
    <div class="domainNameBox" v-for="item in urlList" :key="item.url">
      <div class="appBox">
        <div class="appCover">
          <img :src="item.icon" class="appIcon" />
          <div class="hot"></div>
        </div>
        <div class="appName">{{ item.name }}</div>
      </div>
      <div class="timeConsuming">
        <span v-if="item.time == 0">检测中...</span
        ><span v-else
          ><span v-if="item.time < 50" class="green">{{ item.time }}ms</span>
          <span v-if="item.time >= 50 && item.time < 100" class="orange"
            >{{ item.time }}ms</span
          >
          <span v-if="item.time >= 100" class="red"
            >{{ item.time }}ms</span
          ></span
        >
      </div>
      <!-- <div class="domainName">{{ item.url }}</div> -->
      <div class="intoBtn" @click="jumpUrl(item)">进入</div>
    </div>
    <!-- <div class="turnPageBox">
      <span>滑动翻页</span>
      <div class="downArrow"></div>
    </div> -->
    <div class="resetBox" @click="reset">
      <span>点击这里</span>重新检测刷新访问速度
    </div>
    <div class="tgBox" @click="jumpUrl2('https://t.me/gltwsh')">
      <div class="tg"></div>
      <span>客服TG @gltwsh</span>
    </div>
    <div class="sizeBox"></div>
  </div>
</template>
<script>
import { mixins } from "./../../../mixin/index";

export default {
  name: "mobile",
  mixins: [mixins],
};
</script>
<style lang="scss" scoped>
.mobile {
  height: 100%;
  padding: 18px 16px;
  overflow-y: auto;
  background: url("./../../../assets/png/mobileBg.jpg") no-repeat;
  background-size: 375px 812px;
  .title {
    font-size: 24px;
    font-weight: 800;
    text-align: center;
  }
  .domain {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
  }
  .tip {
    opacity: 0.8;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
  .subTitleBox {
    display: flex;
    align-items: center;
    margin-top: 18px;
    .rectangle {
      width: 6px;
      height: 22px;
      background: rgba(245, 169, 55, 1);
      border-radius: 3px;
    }
    .subTitle {
      color: white;
      font-size: 16px;
      font-weight: 500;
      margin-left: 4px;
    }
  }
  .domainNameBox {
    padding: 8px 12px;
    border: 1px rgba(255, 255, 255, 0.2) solid;
    border-radius: 12px;
    margin: 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .appBox {
      display: flex;
      align-items: center;
      .appCover {
        position: relative;
        height: 50px;
        width: 50px;
        .appIcon {
          height: 50px;
          width: 50px;
          border-radius: 4px;
          overflow: hidden;
        }
        .hot {
          position: absolute;
          right: -5px;
          top: -10px;
          height: 15px;
          width: 15px;
          background: url("./../../../assets/png/hot.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .appName {
        font-size: 12px;
        font-weight: 500;
        margin-left: 15px;
      }
    }
    .timeConsuming {
      color: skyblue;
      font-size: 11px;
      font-weight: 600;
    }
    .green {
      color: #00cf46;
    }
    .orange {
      color: #f5a937;
    }
    .red {
      color: #e93333;
    }
    .domainName {
      max-width: 100px;
      word-wrap: break-word;
    }
    .intoBtn {
      height: 30px;
      width: 58px;
      border-radius: 15.5px;
      color: rgba(51, 51, 51, 1);
      background: rgba(245, 169, 55, 1);
      text-align: center;
      line-height: 30px;
    }
  }
  .turnPageBox {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: #2bdef9;
      font-size: 16px;
    }
    .downArrow {
      height: 16px;
      width: 16px;
      background: url("./../../../assets/png/downArrow.png") no-repeat;
      background-size: 100% 100%;
      margin-left: 6px;
    }
  }
  .resetBox {
    text-align: center;
    padding: 20px 67px;
    border: 1px rgba(255, 255, 255, 0.2) solid;
    border-radius: 12px;
    margin: 16px 0;
    span {
      color: #2bdef9;
      margin-right: 4px;
    }
  }
  .tgBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-radius: 12px;
    color: rgba(51, 51, 51, 1);
    background: rgba(245, 169, 55, 1);
    font-size: 16px;
    .tg {
      height: 24px;
      width: 24px;
      background: url("./../../../assets/png/telegramBlack.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 12px;
    }
  }
  .sizeBox {
    height: 50px;
  }
}
</style>
